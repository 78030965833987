<template>
    <v-dialog
          v-model="dialog"
          max-width="600px"
          :persistent="formLoading"
        >
          <v-card
          >
            <v-card-title class="font-weight-bold justify-center pb-0">
              <span v-if="isDepositType">{{ $i18n.t("deposit_balance") }}</span>
              <span v-else>{{ $i18n.t("withdraw_balance") }}</span>
            </v-card-title>
            <ValidationObserver ref="observer" v-slot="{ invalid, validate }">
            <v-card-text class="pb-0 pt-1 text-left space-y-2">
                <Message v-if="errorCount" severity="error" :closable="false">

                    {{ $t('agent_no_have_balance') }}
                </Message>
                <Message icon="null" severity="info" :closable="false" v-if="agent.parent_agent && agent.parent_agent.id || (isAgent || !isDepositType)">
                    <span class="c-text">
                       {{$t('available_balance')}}:
                    
                    </span>
                    <button type="button" @click="toMaxCount">
                        {{ getAvailableBalance }} {{ getCurrencyValue }}
                    </button>
                </Message>
              <ValidationProvider
                :name="$t('balance')"
                rules="required|min_value:1"
                v-slot="{ errors, validate, validated }"
              > 
                <h4 class="c-text white-space">{{ $t('amount') }}:</h4>
                <div class="d-flex flex-column">
                  <!-- :minFractionDigits="2" -->
                  <InputNumber 
                  locale="en-US"
                  class="fw"
                  :class="{ 'p-invalid' : errors.length > 0 }"
                   ref="inputAmount"
                  mode="currency" 
                  :currency="getCurrencyValue" 
                  currencyDisplay="code" 
                  :placeholder="$t('amount')" 
                  v-model="count" 
                  showButtons  
                  :step="1"
                  :min="0"
                  />
                  <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
            </v-card-text>
            <v-card-actions>
                <Button 
                @click="handleSubmit(validate)"
                :disabled="formLoading"
                :loading="formLoading"
                   :label="isDepositType ? $t('deposit') : $t('withdraw')"
                    icon="pi pi-money-bill" class="p-button-sm p-button-success fw" />
            </v-card-actions>
          </ValidationObserver>
          </v-card>
          <!-- <info-about-new-player 
          @add-new="showAdd = true"
          @close-popup="closeAll"
          v-else
          /> -->
        </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

    export default {
        name: 'BalanceAgent',
        props: {
          isPlayer: {
                type: Boolean,
                default: false
            },
            toAddDialog: {
                type: Boolean,
                default: false
            },
            type: {
              type: String,
              required: true,
            },
            agent: {
                type: Object,
            },
            player: {
                type: Object,
            },
        },
        computed:{
          ...mapGetters({
            tokenAgent: 'tokenUser',
            admin: 'admin',
          }),
          isDepositType() {
            return this.type === 'deposit';
          },
          isAgent(){
            if (this.tokenAgent === 'agent') {
              return 'agent';
            } else{
              return null;
            }
          },
          getAvailableBalance(){
            if (this.player) {
              if (this.isDepositType) {
                return this.player.agent.credits_balance;
              } else{
                return this.player.balance;
              }
            }
            if (this.isDepositType && this.agent.parent_agent && this.agent.parent_agent.id) {
              return this.agent.parent_agent.credits_balance;
            } else{
              return this.agent.credits_balance;
            }
          },
          getCurrencyValue(){
            if (this.isAgent) {
              return this.admin.currency;
            } else if (this.agent.currency) {
              return this.agent.currency;
            } else{
              return 'USD';
            }
          },
        },
        data() {
            return {
              isSubAgentEdit: false,
                formLoading: false,
                dialog: false,
                showAdd: true,
                errorCount: false,
                count: 0,
                holdDeposit: 0,
                holdWithdraw: 0
            }
        },
        async mounted() {
          
        },
        watch:{
          dialog: {
  handler(newVal) {
    const updateCount = () => {
      this.count = this.isDepositType ? this.holdDeposit : this.holdWithdraw;
    };

    const storeCount = () => {
      if (this.isDepositType) {
        this.holdDeposit = this.count;
      } else {
        this.holdWithdraw = this.count;
      }

    };

    if (newVal) {
      this.$nextTick(() => {
        // this.$refs.inputAmount.input.focus();
      })
      updateCount();
    } else {
      this.$emit('dialog');
      this.errorCount = false;

      if (this.toAddDialog) {
        storeCount();

        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      }
    }
  },
  immediate: true
},
          toAddDialog: {
            handler(newVal) {
              this.dialog = newVal;
              if (this.agent.parent_agent) {
                  this.isSubAgentEdit = true;
                } else{
                  this.isSubAgentEdit = false;
                }
            },
            immediate: true
          }
        },
        methods: {
          toMaxCount(){
            
            if (this.isDepositType) {
              this.count = this.getAvailableBalance;
            } else{
              if (this.player) {
              this.count = this.player.balance;
            } else{
              this.count = this.getAvailableBalance;
            }
            }
          },
          handleSubmit(validate) {
            validate().then(valid => {
        if (valid) {
          this.errorCount = false
          if (this.isAgent) {
            if (this.player && !this.isDepositType) {
              if (this.count > this.player.balance) {
                this.errorCount = true;
              } else {
                this.toChangeBalance();
              }
            }
            else if (this.count > this.getAvailableBalance) {
                this.errorCount = true;
            } else if (this.count > this.getAvailableBalance && !this.isDepositType) {
                this.errorCount = true;
            } else{
              this.toChangeBalance();
            }
          } else{
            if (this.count > this.agent.credits_balance && !this.isDepositType) {
                this.errorCount = true;
            } else if (this.count > this.getAvailableBalance && this.agent.parent_agent && this.agent.parent_agent.id) {
                this.errorCount = true;
            } else{
              this.toChangeBalance();
            }
            }
        }
      });
    },
            async toChangeBalance(){
          this.formLoading = true;
          try {
            const data = {
                amount: this.count,
                transfer_type: this.type
            }
            if (this.isPlayer) {
              data.user_id = this.player.id;
              await this.$store.dispatch('agents/awaitBalanceUser', data);
            } else{
              data.agent_id = this.agent.id
              await this.$store.dispatch('agents/awaitBalanceAgentByAdmin', data);
            }
            if (this.isDepositType) {
              this.holdDeposit = 0;
              this.$toast.add({severity: 'success', summary: this.$t('success_deposit'), life: 3000}) 
            } else{
              this.$toast.add({severity: 'success', summary: this.$t('success_withdraw'), life: 3000}) 
              this.holdWithdraw = 0;
            }
            this.amount = 0;
            if (this.isAgent) {
              await this.$store.dispatch('getAgentStatus');
            }
            this.$emit('added')
            //if (this.isSubAgentEdit) {
            //  
            //  this.$emit('added-sub-agent')
            //} else{
            //  this.$emit('update-agent', this.agent.id)
            //}
            this.$emit('dialog')
            this.$nextTick(() => {
                    
                    this.$refs.observer.reset();
                  })
          } catch (error) {
            this.$toast.add({severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 3000}) 
            
          } finally{

            this.formLoading = false; 
          }
            // this.showAdd = false;
        },

        }
    }
</script>

<style lang="scss" scoped>
.v-card__text{
  >span{
    display: flex;
    align-items: center;
    //justify-content: space-between;
    h4{
      flex: 1 1 auto;
    }
    >div{
      flex: 0 0 300px;
    }
    .p-component{
      max-width: 300px;
      //flex: 0 0 300px;
    }
  }
}
</style>