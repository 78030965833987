<template>
  <v-main>
    <div class="main-content">
      <Card class="sticky-filters card-custom">
        <template #title>
          <Toolbar class="mb-1">
            <template #start>
              <div class="d-flex flex-column align-start">
                <h3 class="text-left">
                  {{ $i18n.t("search") }} {{ $i18n.t("found") }}:
                  {{ adminUsers.items.length }}
                  <div class="legend">
                    <div class="d-flex align-center">
                      <div 
                      class="bg-green border-1 mini-square"
                      ></div>
                      <div class="ml-1">- {{ $i18n.t('players_with_deposit') }}</div>
                    </div>
                    <div class="d-flex align-center">
                      <div 
                      style="opacity: .2;"
                      class="bg-black border-1 mini-square"
                      ></div>
                      <div class="ml-1">- {{ $i18n.t('players_without_deposit') }}</div>
                    </div>
                  </div>
                </h3>
                <div class="d-flex align-center">
                  <Button 
                  v-if="filters.agent_id"
                  @click.stop="toClearFilterAgent"
                  class='p-button-sm p-button-danger mr-1'
                  icon="pi pi-times"
                  />
                  <paggination-listbox
                  v-model="filters.agent_id"
                  @change="applyFilters"
                  :options="agents"
                  @start-loading="startAgentsLoading"
                  :maxWidthMenu="600"
                  :loading="loadingAgentsFlag"
                  :loadingStart="startAgentsLoadingFlag"
                  @load-next-page="loadAgentsNextPage"
                  :placeholder="$t('agents')"
                  optionValue="id" optionLabel="login"
                  ></paggination-listbox>
                </div>
              </div>
            </template>
            <template #end>
              <Button
                :disabled="loadingFlag"
                :loading="loadingFlag"
                @click="toCreatePlayer"
                :label="$t('create_user')"
                icon="pi pi-plus"
                class="p-button-sm p-button-success"
              />
            </template>
          </Toolbar>
          <v-chip-group>
                <Chip class="active-filter-chip mr-1" v-for="(value, key) in filteredActiveFilters" 
                :key="'filter' + key"
                  >
                  <template>
                    {{ getFilterValueTitle(key) }}: {{ getFilterValueText(key, value) }}
                  </template>
                  <v-icon @click.stop="removeFilter(key, value)">mdi-close</v-icon>
                </Chip>
              </v-chip-group>
        </template>
        <template #content>
          <!-- 
          @row-expand="onRowExpand" 
             
          filterDisplay="row" 
          selectionMode="single"
          @row-select="selectAgent"
          dataKey="id"
          -->
          <DataTable
            :value="adminUsers.items"
            :rows="adminUsers.size"
            :loading="loadingFlag"
            responsiveLayout="scroll"
            filterDisplay="row"
            stripedRows
            removableSort
            class="p-datatable-sm"
          >
            <template #empty>
              <div>{{ $t("no_data") }}</div>
            </template>
            <!-- bodyClass="justify-end" -->
            <Column 
              field="agent_id"
              :showFilterMenu="false"
              :styles="{ 'min-width': '10rem' }"
            >
              <template #header>
                {{ $t("agent") }}
                <!-- <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.right="$t('online_hint')"></Badge> -->
              </template>
              <template #body="{ data }">
                <template v-if='data.agent'>
                  <router-link v-if="data.agent.id !== admin.id" class="font-weight-bold" :to="{ name: 'agents', params: { login: data.agent.id } }">
                    {{ data.agent.login }}
                  </router-link>
                  <div class="font-weight-bold" v-else-if="data.agent.id === admin.id">
                    {{ data.agent.login }} ({{ $t('you') }})
                  </div>
                </template>
                <div class="font-weight-bold" v-else>---</div>
              </template>
            </Column>
            <Column
              field="is_online"
              :showFilterMenu="false"
              :styles="{ width: '5rem', 'padding-left': '8px' }"
            >
              <template #header>
                {{ $t("online") }}
                <!-- <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.right="$t('online_hint')"></Badge> -->
              </template>
              <template #body="{ data }">
                <div
                  class="online-circle"
                  :class="{ 'is-online': data.is_online }"
                ></div>
              </template>
              <template #filter>
                <div class="field-checkbox d-flex align-center">
                  <Checkbox
                    id="binary_online"
                    @change="getUsers"
                    v-model="filters.is_online"
                    :binary="true"
                  />
                </div>
              </template>
            </Column>
            <!-- <Column
              sortable
              field="country_code"
              :showFilterMenu="false"
              :styles="{ 'min-width': '9rem' }"
            >
              <template #header>
                {{ $t("country") }}
              </template>
              <template #body="{ data }">
                {{ data.country_code }}
              </template>
              <template #filter>
                <InputText
                  type="text"
                  @input="debounceFilters"
                  v-model="filters.country_code"
                  class="p-inputtext-sm"
                  :placeholder="$t('country')"
                />
              </template>
            </Column> -->
            <Column
              sortable
              field="id"
              :showFilterMenu="false"
              :styles="{ 'min-width': '12rem' }"
            >
              <template #header>
                ID
                <!-- <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('id_hint')"></Badge> -->
              </template>
              <template #body="{ data }">
                <div class="d-flex align-center">
                  <router-link
                    class="c-main-flamingo link-hover"
                    :to="`player/${data.id}`"
                  >
                  {{ data.id }} 
                </router-link>
                <span class="ml-1"> ({{ data.username }})</span>
                </div>
              </template>
              <template #filter>
                <v-menu
                  v-model="userMenu"
                  :close-on-content-click="false"
                  offset-y
                  nudge-left="0"
                  nudge-right="0"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on" class="p-float-label p-input-icon-right">
                      <i class="pi pi-user" />
                      <InputText
                        type="text"
                        readonly
                        class="p-inputtext-sm pr-8"
                        :placeholder="getIdAndUsername"
                      >
                      </InputText>
                    </span>
                  </template>
                  <div class="input-menu__menu">
                    <InputText
                  @input="debounceFilters"
                  v-model="filters.id[0]"
                  class="p-inputtext-sm"
                  placeholder="ID"
                />
                <InputText
                @input="debounceFilters"
                v-model="filters.username"
                class="p-inputtext-sm"
                :placeholder="$t('username')"
              />
                  </div>
                </v-menu>
                <!-- <InputText
                  type="text"
                  @input="debounceFilters"
                  v-model="filters.id[0]"
                  class="p-inputtext-sm"
                  placeholder="ID"
                /> -->
              </template>
            </Column>
            <Column
              sortable
              field="total_deposit_amount"
              :showFilterMenu="false"
              :styles="{ 'min-width': '10rem' }"
            >
              <template #header>
                {{ $t("deposits") }}
                <!-- <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('deposits_hint')"></Badge> -->
              </template>
              <template #body="{ data }">
                <div class="d-flex align-center">
                  <div class="mr-1">
                    <div
                      v-if="data.total_deposit_amount > 0"
                      style="
                        width: 20px;
                        height: 20px;
                        background: var(--green-500);
                        border-radius: 3px;
                      "
                    ></div>
                    <div
                      v-else
                      style="
                        width: 20px;
                        height: 20px;
                        background: var(--black);
                        border-radius: 3px;
                        opacity: 0.2;
                      "
                    ></div>
                  </div>
                  <div class="d-flex align-center">
                    <span>
                      {{ data.total_deposit_amount.toLocaleString() }}
                    </span>
                    <span class="ml-1">
                      {{ data.currency }}
                    </span>
                  </div>
                </div>
              </template>
              <template #filter>
                <div class="field-checkbox">
                  <Checkbox
                    id="binary"
                    @change="getUsers"
                    v-model="filters.has_deposit"
                    :binary="true"
                  />
                </div>
              </template>
            </Column>
            <Column
              sortable
              field="total_withdraw_amounts"
              :showFilterMenu="false"
              :styles="{ 'min-width': '8rem' }"
            >
              <template #header>
                {{ $t("withdrawals") }}
                <!-- <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('withdrawals_hint')"></Badge> -->
              </template>
              <template #body="{ data }">
                <div class="d-flex align-center">
                  <span
                    >{{ data.total_withdraw_amounts.toLocaleString() }}
                  </span>
                  <span class="ml-1">{{ data.currency }}</span>
                </div>
              </template>
              <template #filter>
                <v-menu
                  v-model="withdrawalsMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  nudge-left="0"
                  nudge-right="0"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on" class="p-float-label p-input-icon-right">
                      <i class="pi pi-money-bill" />
                      <InputText
                        type="text"
                        readonly
                        class="p-inputtext-sm pr-8"
                        :placeholder="getWithdrawPlaceholder"
                      >
                      </InputText>
                    </span>
                  </template>
                  <div class="input-menu__menu">
                    <!-- <InputText
                      type="text"
                      @input="debounceFilters"
                      v-model="filters.min_withdrawal"
                      class="p-inputtext-sm"
                      :placeholder="$t('amount_from')"
                    />
                    <InputText
                      type="text"
                      @input="debounceFilters"
                      v-model="filters.max_withdrawal"
                      class="p-inputtext-sm"
                      :placeholder="$t('amount_to')"
                    /> -->
                    <InputNumber 
                    class="p-inputtext-sm"
                    @input="debounceFilters"
                    :placeholder="$t('amount_from')" 
                    v-model="filters.min_withdrawal" 
                    showButtons  
                    :step="1"
                    :min="0"
                    />
                    <InputNumber 
                    class="p-inputtext-sm"
                    @input="debounceFilters"
                    :placeholder="$t('amount_to')" 
                    v-model="filters.max_withdrawal" 
                    showButtons  
                    :step="1"
                    :min="1"
                    />
                  </div>
                </v-menu>
              </template>
            </Column>
            <Column
              sortable
              field="balance"
              :showFilterMenu="false"
              :styles="{ 'min-width': '10rem' }"
            >
              <template #header>
                {{ $t("balance") }}
                <!-- <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('balance_hint')"></Badge> -->
              </template>
              <template #body="{ data }">
                <div
                  class="d-flex align-center px-1"
                  style="max-width: 190px;"
                  v-if="tokenUser === 'agent'"
                >
                  <Button
                    icon="pi pi-minus"
                    @click="removeCredits(data)"
                    class="p-button-sm p-button-danger button-agent-balance"
                  />
                  <span class="mx-1 flex-1 text-center"
                    >{{ data.balance }} {{ data.currency }}</span
                  >
                  <Button
                    icon="pi pi-plus"
                    @click="addCredits(data)"
                    class="p-button-sm p-button-success button-agent-balance"
                  />
                </div>
                <span v-else class="mx-1 flex-1 text-center"
                  >{{ data.balance }} {{ data.currency }}</span
                >
              </template>
              <template #filter>
              <v-menu v-model="balanceMenu" :close-on-content-click="false" 
              offset-y
                nudge-left="0" nudge-right="0">
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="p-float-label p-input-icon-right">
                    <i class="pi pi-money-bill" />
                    <InputText type="text" readonly class="p-inputtext-sm pr-8" 
                    :placeholder="
                    getBalancePlaceholder
                    ">
                    </InputText>
                  </span>
                </template>
                <div class="input-menu__menu">
                  <!-- <InputText type="text" @input="debounceFilters" v-model="filters.min_balance" class="p-inputtext-sm"
                    :placeholder="$t('balance_from')" /> -->
                    <InputNumber 
                    class="p-inputtext-sm"
                    @input="debounceFilters"
                    :placeholder="$t('balance_from')" 
                    v-model="filters.min_balance" 
                    showButtons  
                    :step="1"
                    :min="0"
                    />
                    <InputNumber 
                    class="p-inputtext-sm"
                    @input="debounceFilters"
                    :placeholder="$t('balance_to')" 
                    v-model="filters.max_balance" 
                    showButtons  
                    :step="1"
                    :min="1"
                    />
                  <!-- <InputText type="text" @input="debounceFilters" v-model="filters.max_balance" class="p-inputtext-sm"
                    :placeholder="$t('balance_to')" /> -->
                </div>
    
              </v-menu>
            </template>
            </Column>
            <Column
              sortable
              field="ggr"
              :showFilterMenu="false"
              :styles="{ 'min-width': '10rem' }"
            >
              <template #header>
                {{ $t("ggr") }}
              </template>
              <template #body="{ data }">
                <span v-if="data.ggr" class="mx-1 flex-1 text-center"
                  >{{ data.ggr }} {{ data.currency }}</span
                >
              </template>
              <template #filter>
            </template>
            </Column>

            <Column
              sortable
              field="created_at"
              :showFilterMenu="false"
              :styles="{ 'min-width': '12rem' }"
            >
              <template #header>
                {{ $t("registered") }}
                <!-- <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('registered_hint')"></Badge> -->
              </template>
              <template #body="{ data }">
                <div style="white-space: nowrap">
                  {{ new Date(data.created_at).toLocaleString() }}
                </div>
              </template>
              <template #filter>
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <InputText
                      v-on="on"
                      type="text"
                      readonly
                      class="p-inputtext-sm"
                      :placeholder="$t('date')"
                      v-model="getFilteredDates"
                    >
                    </InputText>
                  </template>
                  <Calendar
                    class="calendar-menu"
                    :placeholder="$t('date')"
                    :inline="true"
                    :maxDate="today"
                    id="icon"
                    dateFormat="mm.dd.yy"
                    v-model="datesToFilter"
                    selectionMode="range"
                  />
                </v-menu>
              </template>
            </Column>
            <Column
              sortable
              field="block"
              :showFilterMenu="false"
              :styles="{ 'min-width': '12rem' }"
            >
              <template #header>
                {{ $t("account_status") }}
                <!-- <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('account_status_hint')"></Badge> -->
              </template>
              <template #body="{ data }">
                <div class="d-flex align-center">
                  <!-- <v-chip
                    :color="getChipBackgroundColor(data.block, data.block_end)"
                    style="color: var(--white)"
                    label
                    small
                  >
                </v-chip> -->
                <span class="custom-badge"
                :class="getAccountStatusClass(data.block, data.block_end)"
                >
                  <span>{{ getAccountStatus(data.block, data.block_end) }} </span>
                  <span v-if="!data.block && data.block_end">({{ formatDate(data.block_end) }})</span>
                </span>
                </div>
              </template>
              <template #filter>
                <div id="select-option">
                  <v-menu
                    v-model="optionMenu"
                    offset-y
                    :close-on-content-click="false"
                    nudge-left="0"
                    nudge-right="0"
                  >
                    <template v-slot:activator="{ on }">
                      <span class="p-input-icon-right" v-on="on">
                        <i class="pi pi-angle-down" />
                        <InputText
                          :value="
                            selectedOption && selectedOption.text
                              ? selectedOption.text
                              : ''
                          "
                          type="text"
                          readonly
                          class="p-inputtext-sm"
                          :placeholder="$t('method')"
                        >
                        </InputText>
                      </span>
                    </template>
                    <Listbox
                      v-model="selectedOption"
                      :options="options"
                      @change="handleChange($event)"
                      :emptyFilterMessage="$t('no_data')"
                      optionLabel="text"
                      style="width: 15rem"
                    />
                  </v-menu>
                </div>
              </template>
            </Column>
            <Column
              :header="$t('actions')"
              headerStyle="width: 18em"
              style="min-width: 250px"
              v-if="isAgent"
            >
              <template #body="{ data }">
                <div class="space-x-1 d-flex align-center">
                  <Button
                    @click="toManagePlayer(data)"
                    
                    icon="pi pi-lock"
                    class="button-agent-balance _square"
                  />
                  <Button
                    @click="toEditPlayer(data)"
                    
                    icon="pi pi-pencil"
                    class="button-agent-balance _square"
                  />
                  <Button
                    @click="toCheckPlayer(data)"
                    
                    icon="pi pi-eye"
                    class="button-agent-balance _square"
                  />
                </div>
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
      <games-paggination 
                        :pagginationObject="filters" 
                        :requestData="adminUsers" 
                        @apply-filters="getUsers" 
                        :isStatic="false"
                        />
      <modal-actions-user-for-agent
        @added="applyFilters"
        @added-edit="toClosedActionsPlayer"
        :isEditPlayer="isEditPlayer"
        :isCreatePlayer="isCreatePlayer"
        :player="selectedPlayer"
        @dialog="toClosedEditPlayer"
        :toAddDialog="toAddDialog"
      />
      <modal-manage-user-for-agent
        @added="getUsers"
        :player="selectedPlayer"
        @dialog="toClosedManagePlayer"
        :toAddDialog="toManageDialog"
              />
      <balance-agent
        @added="getUsers"
        :type="typeOfBalance"
        :isPlayer="true"
        :player="selectedPlayer"
        :agent="currentAgent"
        @dialog="updateCurrentAgent"
        :toAddDialog="toBalanceDialog"
      />
    </div>
  </v-main>
</template>

<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";
import BalanceAgent from "../AgentsList/BalanceAgent";
import ModalManageUserForAgent from "./ModalManageUserForAgent.vue";
import ModalActionsUserForAgent from "./ModalActionsUserForAgent.vue";
import GamesPaggination from '@/views/GamesPaggination';
export default {
  name: "AgentPlayersList",
  components: {
    ModalManageUserForAgent,
    ModalActionsUserForAgent,
    BalanceAgent,
    GamesPaggination
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit("setPageName", "agent_players_list");
    });
  },
  data() {
    return {
      toManageDialog: false,
      options: [
        { value: "active", text: this.$i18n.t("active") },
        { value: "forever_blocked", text: this.$i18n.t("forever_blocked") },
        { value: "temp_blocked", text: this.$i18n.t("temp_blocked") },
        // { value: "blocked_withdraw", text: this.$i18n.t("withdraw_blocked") },
      ],
      loadingAgentsFlag: false,
      startAgentsLoadingFlag: false,
      today: new Date(),
      balanceMenu: false,
      dateMenu: false,
      userMenu: false,
      datesToFilter: [],
      selectedOption: null,
      optionMenu: false,
      withdrawalsMenu: false,
      isEditPlayer: false,
      isCreatePlayer: false,
      toBalanceDialog: false,
      typeOfBalance: "deposit",
      selectedPlayer: {},
      currentAgent: {},
      activeFilters: {},
      toAddDialog: false,
      loadingFlag: false,
      expandedRows: [],
      startPage: 1,
      startSize: 25,
      filters: {
        id: [],
        agent_id: "",
        username: "",
        is_agent_user: true,
        page: 1,
        size: 25,
      },
    };
  },
  watch: {
    datesToFilter(newValue, oldValue){
            if (newValue[0] && newValue[1]) {
              this.getUsers()
            }
          },
    '$route.params.agent': {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.filters.agent_id = newValue;
          this.getUsers();
        }
      }
    },
    '$route.params.player_id': {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.filters.id = newValue;
          this.getUsers();
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      agents: "agents/getAgents",
      agentUsers: "agents/getAgentUsers",
      adminUsers: "getPlayers",
      allAgents: "agents/getAllAgents",
      tokenUser: "tokenUser",
      admin: "admin",
    }),
    filteredActiveFilters() {
  const isEmpty = value => {
    if (typeof value === 'string' && value === '') {
      return true;
    }
    if (Array.isArray(value)) {
      return value.length === 0 || value.every(item => item === '');
    }
    return value == null;
  };

  const activeFilters = Object.entries(this.activeFilters).filter(
    
    ([key, value]) =>{
      if (this.tokenUser === 'agent' && key === 'agent_id') {
        return false;
      }
      return key !== "size" &&
      key !== "page" &&
      (key === "is_active" || value !== false) && key !== "is_agent_user" &&
      !isEmpty(value)
    }
    
  );

  const activeFiltersObject = Object.fromEntries(activeFilters);

  if (Array.isArray(activeFiltersObject.id)) {
    activeFiltersObject.id.forEach((id, index) => {
      if (!isEmpty(id)) {
        activeFiltersObject[`ID`] = id;
      }
    });
    delete activeFiltersObject.id;
  }

  return activeFiltersObject;
},
    getBalancePlaceholder() {
      const { min_balance, max_balance } = this.filters;
      if (min_balance && max_balance) {
        
      
      const trimMinBalance = min_balance.trim();
      const trimMaxBalance = max_balance.trim();
        if (!trimMinBalance && !trimMaxBalance) {
          return this.$t('amount');
        } else if (!trimMinBalance) {
          return this.$t('to') + ' ' + trimMaxBalance;
        } else if (!trimMaxBalance) {
          return this.$t('from') + ' ' + trimMinBalance;
        } else if(trimMinBalance && trimMaxBalance) {
          return `${this.$t('from')} ${trimMinBalance} ${this.$t('to')} ${trimMaxBalance}`
        } else {
          return this.$t('amount');
        }
      } else{
        return this.$t('amount')
      }
    },
    getIdAndUsername() {
      const { id, username } = this.filters;
      if (id[0] && username) {
      const trimFirstValue = id[0].trim();
      const trimSecondValue = username.trim();
        if (!trimFirstValue && !trimSecondValue) {
          return this.$t('user');
        } else if(trimFirstValue && trimSecondValue) {
          return `ID: ${trimFirstValue}, ${this.$t('username')}: ${trimSecondValue}`
        } 
      } else if (id[0]) {
        const trimFirstValue = id[0].trim();
        return 'ID:' + ' ' + trimFirstValue;
      } else if (username) {
        const trimSecondValue = username.trim();
        return this.$t('username') + ': ' + trimSecondValue;
      } else {
        return this.$t('user');
      }
    },
    
    getFilteredDates() {
      if (this.datesToFilter.length > 0) {
        let one;
        let two;
        const formattedDates = this.datesToFilter.map((dateStr) => {
          const date = new Date(dateStr);
          return date.toISOString().split("T")[0];
        });
        const formattedDateOne = formattedDates[0];
        const formattedDateTwo = formattedDates[1];
        //console.log('formattedDateOne', formattedDateOne);
        //console.log('formattedDateTwo', formattedDateTwo);
        //console.log('this.datesToFilter[0]', this.datesToFilter[0]);
        //console.log('this.datesToFilter[1]', this.datesToFilter[1]);
        if (this.datesToFilter.length === 2 && !this.datesToFilter[0] & !this.datesToFilter[1]) {
          return "";
        }
        if (this.datesToFilter.length === 2 && this.datesToFilter[1]) {
          // both data
          one = formattedDateOne;
          two = formattedDateTwo;
          return `${one} - ${two}`;
        } else {
          // only one
          one = formattedDateOne;
          return `${one} - ${one}`;
        }
      } else {
        return "";
      }
    },
    getWithdrawPlaceholder() {
      const { min_withdrawal, max_withdrawal } = this.filters;
      if (min_withdrawal && max_withdrawal) {
        const trimMinBalance = min_withdrawal.trim();
        const trimMaxBalance = max_withdrawal.trim();
        if (!trimMinBalance && !trimMaxBalance) {
          return this.$t("amount");
        } else if (!trimMinBalance) {
          return this.$t("to") + " " + trimMaxBalance;
        } else if (!trimMaxBalance) {
          return this.$t("from") + " " + trimMinBalance;
        } else if (trimMinBalance && trimMaxBalance) {
          return `${this.$t("from")} ${trimMinBalance} ${this.$t(
            "to"
          )} ${trimMaxBalance}`;
        } else {
          return this.$t("amount");
        }
      } else {
        return this.$t("amount");
      }
    },
    isAgent() {
      if (this.tokenUser === "agent") {
        return "agent";
      } else {
        return null;
      }
    },
    // userArrayAgentOrAdmin() {
    //   if (this.tokenUser && this.tokenUser === "agent") {
    //     return this.agentUsers;
    //   } else if (this.adminUsers.items) {
    //     return this.adminUsers;
    //   } else {
    //     return {
    //       items: [],
    //       size: 0,
    //     };
    //   }
    // },
  },
  async mounted() {
    this.loadingFlag = true;
    if (!this.agents.items.length && this.tokenUser !== "agent") {
      await this.$store.dispatch("agents/awaitGetAgents", this.filters);
    }
    if (!this.agentUsers.items.length) {
      await this.getUsers();
    } else{
      this.loadingFlag = false
    }
  },
  methods: {
    debounceFilters: debounce(async function () {
      await this.applyFilters();
    }, 750),
    async startAgentsLoading(){
          if (this.agents.items.length) {
            return
          }
          this.startAgentsLoadingFlag = true;
          const filters = {
            size: 100,
            page: 1
          }
          try {
            await this.$store.dispatch("agents/awaitGetAgents", filters);
          } catch (error) {
            this.$toast.add({ severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 4000 })
          } finally{
            this.startAgentsLoadingFlag = false;
          }
        },
        async loadAgentsNextPage(){
          this.loadingAgentsFlag = true;
          const filters = {
            size: 100,
            page: this.agents.page + 1
          }
          try {
            await this.$store.dispatch("agents/awaitGetAgents", filters);
          } catch (error) {
            this.$toast.add({ severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 4000 })
          } finally{
            this.loadingAgentsFlag = false;
          }
        },
    formatDate(date) {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      return date.toLocaleDateString();
    },
    toManagePlayer(player) {
      this.toManageDialog = true;
      this.selectedPlayer = player;
    },
    toClosedManagePlayer(){
      this.toManageDialog = false;
      // this.toAddDialog = false;
      this.toBalanceDialog = false;
      this.selectedPlayer = {};
    },
    toClosedActionsPlayer(){
      this.toAddDialog = false;
      this.applyFilters();
      this.selectedPlayer = {};
    },
    async updateCurrentAgent() {
      this.toBalanceDialog = false;
      //if (this.tokenUser === "agent") {
      //  await this.$store.dispatch("getAgentStatus");
      //}
    },
    toClearFilterAgent() {
      this.filters.agent_id = null;
      this.applyFilters();
    },
    async applyFilters() {
        this.filters.page = this.startPage;
        this.filters.size = this.startSize;
        this.getFormattedDate();
        this.$store.commit('paggination/SET_FILTERS', this.filters);
        await this.getUsers();
    },
    async getUsers() {
      this.loadingFlag = true;
      // console.log('tokenUser', this.tokenUser);
      // console.log('admin', this.admin);
      // if (this.tokenUser === "agent") {
      //   this.filters.agent_id = this.admin.id;
      //   await this.getAgentUsers();
      // } else {
      // }
      this.toClosedManagePlayer();
      await this.getUsersByAgentFilter();
      this.loadingFlag = false;
      for (const [key, value] of Object.entries(this.filters)) {
        if (value !== '') {
          this.$set(this.activeFilters, key, value);
        } else {
          this.$delete(this.activeFilters, key);
        }
      }
    },
    getFormattedDate(){
      if (this.datesToFilter.length > 0) {
          const formattedDates = this.datesToFilter.map(dateStr => {
            const date = new Date(dateStr);
            return date.toISOString().split('T')[0];
          });
          const formattedDateOne = formattedDates[0];
          const formattedDateTwo = formattedDates[1];
          if (this.datesToFilter.length === 2) {
            // both data
            this.filters.min_reg_date = formattedDateOne;
            this.filters.max_reg_date = formattedDateTwo;
          } else if (this.datesToFilter.length === 1) {
            // only one
            this.filters.min_reg_date = formattedDateOne;
          }
        }
    },
    async getUsersByAgentFilter() {
      
      try {
        const fil = this.filters;
        const params = new URLSearchParams();
               const isEmpty = value => {
       if (typeof value === 'string' && value === '') {
         return true;
       }
       if (Array.isArray(value)) {
         return value.length === 0 || value.every(item => item === '');
       }
       return value == null;
        };

        const cleanedFilters = {};
        for (const key in fil) {
          if (Object.hasOwnProperty.call(fil, key) && !isEmpty(fil[key])) {
            cleanedFilters[key] = fil[key];
          }
        }
        // console.log('cleanedFilters', cleanedFilters);
        for (const key in cleanedFilters) {
          if (Object.hasOwnProperty.call(cleanedFilters, key)) {
            const value = cleanedFilters[key];
            params.append(key, value);
          }
        }
        const queryString = `${params.toString()}`;
        //  console.log('queryString', queryString);
        this.filters.is_agent_user = true;
        await this.$store.dispatch("playersFilters", queryString);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.$t("just_error"),
          detail: error.message,
          life: 4000,
        });
      } finally {
        this.$store.commit('paggination/SET_FILTERS', this.filters);
    this.loadingFlag = false;
    this.$store.commit('paggination/SET_LOADING_FLAG', false);
      }
    },
    
    //========================================================================================================================================================
    getAgentLogin(agent) {
      if (this.agents.items.length === 0) {
        return null
      }
      const findAgent = this.agents.items.find((item) => item.id === agent);
      // console.log('findAgent', findAgent);
      if (findAgent) {
        return findAgent.login
      } else{
        return '?'
      }
    },
    getFilterValueTitle(key) {
      // chip keys
      switch (key) {
        case 'is_active':
          return this.$t('status')
        case 'agent_id':
          return this.$t('agent')
        case 'username':
          return this.$t('username')
        case 'surname':
          return this.$t('surname')
        case 'min_balance':
          return this.$t('balance_from')
        case 'max_balance':
          return this.$t('balance_to')
        case 'id':
          return 'id'
        case 'online':
          return this.$t('online')
        case 'min_reg_date':
          return this.$t('min_reg_date')
        case 'max_reg_date':
          return this.$t('max_reg_date')
        case 'name':
          return this.$t('name')
        case 'email':
          return this.$t('email')
        case 'tel':
          return this.$t('tel_number')
        case 'lvl_name':
          return this.$t('player_status')
        case 'affiliate_id':
          return this.$t('affiliate')
        case 'pending_verification':
          return this.$t('pending_verification')
        case 'blocked_withdraw':
          return this.$t('withdraw_blocked')
        case 'temp_blocked':
          return this.$t('temp_blocked')
        case 'has_multiaccs':
          return this.$t('multi_accounts')
        case 'has_deposit':
          return this.$t('with_deposits')
        case 'is_online':
          return this.$t('online')
        case 'min_balance':
          return this.$t('withdrawals_from')
        case 'max_balance':
          return this.$t('withdrawals_to')
        case 'min_withdrawal':
          return this.$t('min_withdrawal')
        case 'max_withdrawal':
          return this.$t('min_withdrawal')
        case 'country_code':
          return this.$t('country_code')
      }

      return key;
    },
    getFilterValueText(key, value) {
      // chip values
      if (key === 'is_active' && value === true) {
        return this.$t('active')
      }
      if (key === 'is_active' && value === false) {
        return this.$t('forever_blocked')
      }
      switch (value) {
        case true:
          return this.$t('yes')
        case false:
          return this.$t('no')
      }
      if (key === 'agent_id') {
        return this.getAgentLogin(value)
      }
      return value;
    },
    async handleChange(event) {
      if (!event.value) {
        this.filters.blocked_withdraw = false;
        this.filters.temp_blocked = false;
        this.filters.is_active = "";
        this.optionMenu = false;
        await this.getUsers();
        return;
      }
      if (event.value.value === "active") {
        this.filters.is_active = true;
        this.filters.temp_blocked = false;
        this.filters.blocked_withdraw = false;
        // console.log(this.filters.is_active);
      } else if (event.value.value === "forever_blocked") {
        this.filters.is_active = false;
      } else if (event.value.value === "temp_blocked") {
        this.filters.temp_blocked = true;
        this.filters.blocked_withdraw = false;
        this.filters.is_active = "";
      } else if (event.value.value === "blocked_withdraw") {
        this.filters.blocked_withdraw = true;
        this.filters.temp_blocked = false;
        this.filters.is_active = "";
      }
      this.optionMenu = false;
      await this.getUsers();
    },
    getAccountStatus(block, blockEndDate) {
      const currentDate = new Date();
      const endDate = new Date(blockEndDate);

      if (block === 2) {
        return this.$i18n.t("forever_blocked");
      } else if (endDate > currentDate) {
        return this.$i18n.t("temp_blocked");
      } else if (block === 0) {
        return this.$i18n.t("active");
      } else {
        return "NONE";
      }
    },
    getAccountStatusClass(block, blockEndDate) {
      const currentDate = new Date();
      const endDate = new Date(blockEndDate);
      return block === 2
        ? "_red"
        : endDate > currentDate
        ? "_yellow"
        : block === 0
        ? "_green"
        : "_yellow";
    },
    removeCredits(player) {
      this.selectedPlayer = player;
      this.toBalanceDialog = true;
      this.typeOfBalance = "withdraw";
      if (this.tokenUser === "agent") {
        this.currentAgent = this.admin;
      }
    },
    addCredits(player) {
      this.selectedPlayer = player;
      if (this.tokenUser === "agent") {
        this.currentAgent = this.admin;
      }
      this.toBalanceDialog = true;
      this.typeOfBalance = "deposit";
    },
    toClosedEditPlayer() {
      this.toAddDialog = false;
      this.isEditPlayer = false;
      this.isCreatePlayer = false;
    },
    toCreatePlayer() {
      this.selectedPlayer = {};
      this.toAddDialog = true;
      this.isCreatePlayer = true;
    },
    toEditPlayer(player) {
      this.toAddDialog = true;
      this.isEditPlayer = true;
      this.selectedPlayer = player;
    },
    toCheckPlayer(player) {
      this.$router.push({ path: `/player/${player.id}` });
    },
    async removeFilter(key, value) {
      this.loadingFlag = true;
      // Delete chip
      if (key.includes('ID_')) {
        const index = this.activeFilters.id.indexOf(value);
  if (index > -1) {
    this.$delete(this.activeFilters.id, index); // 
  }
      } else{
        this.$delete(this.activeFilters, key);
      }
      // empty string
      if (typeof this.filters[key] === 'boolean') {
        this.$set(this.filters, key, false);
      } else {
        this.$set(this.filters, key, '');
      }
      // is_active
      if (key === 'is_active') {
        this.filters.is_active = '';
        this.selectedOption = null;
      }
      if (key === 'blocked_withdraw') {
        this.filters.blocked_withdraw = '';
        this.selectedOption = null;
      }
      if (key === 'temp_blocked') {
        this.filters.temp_blocked = '';
        this.selectedOption = null;
      }
      if (key === 'min_reg_date') {
        this.datesToFilter[0] = '';
      }
      if (key === 'max_reg_date') {
        this.datesToFilter[1] = '';
      }
        await this.getUsersByAgentFilter();
    },
  },
};
</script>

<style lang="scss" scoped></style>
