<template>
    <v-dialog
          v-model="dialog"
          max-width="500px"
          :persistent="formLoading"
          transition
          >
          <!-- scrollable -->
            <v-card
            >
              <v-card-title class="font-weight-bold justify-center">
                <span class="c-text ml-1">
                  {{ `${$t('player_managed')} - ${player.username}` }}
                </span>
              </v-card-title>
              <ValidationObserver ref="observer" v-slot="{ invalid, validate }">
              <v-card-text class="pb-0 pt-1 text-left space-y-2">
                <div class="field-checkbox d-flex align-center">
                  <Checkbox id="permanentban"
                  @change="banDateTime = null"
                  :disabled="playerHaveBan"
                  v-model="isPermanentBan" :binary="true" />
                  <label for="permanentban" class="ml-1">{{ $t('permanent_ban') }}</label>
                </div>
                <span> 
                  <h4 class="c-text white-space d-flex align-center">
                    {{ $t('date') }}:
                    
                  </h4>
                  <div class="d-flex flex-column">
                    <!-- <Calendar
                    :disabled="isPermanentBan"
                    :placeholder="$t('date')" :touchUI="$isMobile() ? true : false" :minDate="today" id="icon"
                    dateFormat="mm.dd.yy" 
                    v-model="banDateTime" selectionMode="single" /> -->
                    <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <div class="p-inputgroup" v-on="on">
              <Button
              v-if="banDateTime && !playerHaveBan"
              @click.stop="banDateTime = null"
              icon="pi pi-times" class="p-button-danger"/>
              <InputText
                    type="text"
                    readonly
                    :disabled="isPermanentBan || playerHaveBan"
                    class="p-inputtext-sm"
                    :placeholder="$t('date')"
                    v-model="getFilteredDate"
                    dateFormat="mm.dd.yy"
                    >
                  </InputText>
            <!-- :value="filters.currency ? filters.currency : ''" -->
            </div>
          
          </template>
          <Calendar class="calendar-menu fw" 
          @date-select="dateMenu = false"
          :placeholder="$t('date')" :inline="true" :minDate="today" id="icon" dateFormat="mm.dd.yy" v-model="banDateTime" selectionMode="single" />
        </v-menu>
                    <span v-if="errorDate" class="p-error mt-1 font-weight-bold">{{ $t('required_field') }}</span>
                  </div>
              </span>


              </v-card-text>
              <v-card-actions>
                  <Button 
                  @click="handleSubmit"
                  :disabled="formLoading"
                  :loading="formLoading"
                     :label="playerHaveBan ? $t('unblock') : banDateTime ? $t('temp_block') : $t('block')"
                      :icon="playerHaveBan ? 'pi pi-unlock' : 'pi pi-ban'" 
                      :class="playerHaveBan ? 'p-button-success' : banDateTime ? 'p-button-warning' : 'p-button-danger'"
                      class="p-button-sm fw" />
              </v-card-actions>
            </ValidationObserver>
            </v-card>
        </v-dialog>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
    export default {
        name: 'ModalManageUserForAgent',
        components:{
        },
        props: {
            toAddDialog: {
                type: Boolean,
                default: false
            },
            player:{
              type: Object,
            }
        },
        computed:{
          ...mapGetters({
            currencies: 'getFilteredCurrencies',
            agents: "agents/getAgents",
            admin: 'admin',
            tokenUser: 'tokenUser',
          }),
          getFilteredDate(){
            if (this.banDateTime) {
    const date = new Date(this.banDateTime);
    const formattedDate = date.toLocaleDateString('en-CA');
    return `${formattedDate}`;
  } else {
    return '';
  }
    },
          getCurrencyValue(){
            if (this.isAgent) {
              return this.admin.currency;
            } else if (!this.isAgent) {
            const parentAgent = this.agents.find(agent => agent.id === this.addUser.agent_id);
            if (parentAgent) {
              return parentAgent.currency;
            } else {
              return 'USD';
            }
            } else{
              return 'USD';
            }
          },
          isAgent(){
            if (this.tokenUser === 'agent') {
              return 'agent';
            } else{
              return null;
            }
          },
        },
        data() {
            return {
              dateMenu: false,
              today: new Date(),
              banDateTime: null,
              playerHaveBan: false,
                formLoading: false,
                isPermanentBan: false,
                dialog: false,
                balanceCheck: 0,
                errorDate: false,
            }
        },
        async mounted() {
          // console.log('this.admin.id', this.admin.id);
        },
        watch:{
          player:{
            handler(newVal){
              if (newVal.block_end && newVal.block === 0) {
                this.playerHaveBan = true;
                this.banDateTime = newVal.block_end;
              } else if (newVal.block === 2) {
                this.playerHaveBan = true;
                this.isPermanentBan = true;
              } else{
                this.isPermanentBan = false;
                this.banDateTime = null;
                this.playerHaveBan = false;
              }
            },
            deep: true
          },
          dialog: {
            handler(newVal) {
              if (newVal === false) {
                this.$emit('dialog');
                if (this.toAddDialog) {
                  
                  this.$nextTick(() => {
                    
                    this.$refs.observer.reset();
                  })
                }
              }
            },
            immediate: true
          },
          toAddDialog: {
            handler(newVal) {
              this.dialog = newVal;
            },
            immediate: true
          }
        },
        methods: {
          handleSubmit() {
          if (!this.banDateTime && !this.isPermanentBan) {
                this.errorDate = true;
            } else{
                this.errorDate = false
                this.toManageUser();
            }
    },
          closeAll(){
            this.$emit('dialog');
            this.dialog = false;
          },
            async toManageUser(){
          this.formLoading = true;
          try {
            const userTimezone = moment.tz.guess();
            const offset = moment.tz(userTimezone).format('Z');
            let blockInfo = {
              user_id: this.player.id,
              block: 0,
              tz_offset: offset,
            }
            if (!this.playerHaveBan) {
              
              if (this.isPermanentBan) {
                blockInfo.block = 2
                
              } else if (this.banDateTime){
                const date = new Date(this.banDateTime);
                const formattedDates = date.toLocaleDateString('en-CA');
                blockInfo.block_end = formattedDates
              }
            }
            await this.$store.dispatch(`agents/awaitManageUser`, blockInfo);
            this.$toast.add({severity: 'success', summary: this.playerHaveBan ? this.$t('player_unbaned') : this.$t('player_baned'), life: 3000}) 
            this.$emit('added')
          } catch (error) {
            if (error.response && error.response.data.detail.loc === 'is_active') {
              this.$toast.add({severity: 'error', summary: this.$t('cant_unban_player'), detail: error.message, life: 3000}) 
            } else{
              this.$toast.add({severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 3000}) 
            }
            
          } finally{

            this.formLoading = false; 
          }
        },
        },
    }
</script>

<style lang="scss" scoped>
.v-card__text{
  >span{
    display: flex;
    align-items: center;
    //justify-content: space-between;
    h4{
      flex: 1 1 auto;
    }
    >div{
      flex: 0 0 300px;
    }
    .p-component{
      max-width: 300px;
      //flex: 0 0 300px;
    }
  }
}
</style>