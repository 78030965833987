<template>
    <v-dialog
          v-model="dialog"
          max-width="600px"
          :persistent="formLoading"
          transition
          >
          <!-- scrollable -->
            <v-card
            v-if="showAdd"
            >
              <v-card-title class="font-weight-bold justify-center">
                <span v-if="isEditPlayer" class="c-text ml-1">
                  {{ `${$t('player_edit')} - ${player.username}` }}
                </span>
                <span v-else>{{ $i18n.t("new_user") }}</span>
              </v-card-title>
              <ValidationObserver ref="observer" v-slot="{ invalid, validate }">
              <v-card-text class="pb-0 pt-1 text-left space-y-2">
                <template v-if="!isEditPlayer && addPlayer.agent_id">
                  <div>
                    <Message v-if="errorCount" severity="error" :closable="false">
      
                      {{ $t('agent_no_have_balance') }}
                  </Message>
                  <Message icon="null" severity="info" :closable="false">
                      <span class="c-text">
                         {{$t('available_balance')}}:
                      
                      </span>
                      <button type="button" @click="toMaxCount">
                          {{ getAdminAvailableBalance }} {{ getCurrencyValue }}
                      </button>
                  </Message>
                  </div>
                </template>
                <ValidationProvider
                v-if="!isAgent"
                  name="agent_id"
                  rules="required"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space d-flex align-center">
                    {{ $t('parent_agent') }}<span class="c-red">*</span>:
                    
                  </h4>
                  <div class="d-flex flex-column">
                    <paggination-listbox
                  v-model="addPlayer.agent_id"
                  :options="agents"
                  @start-loading="startAgentsLoading"
                  :maxWidthMenu="600"
                  :loading="loadingAgentsFlag"
                  full-width
                  :loadingStart="startAgentsLoadingFlag"
                  @load-next-page="loadAgentsNextPage"
                  :placeholder="$t('agents')"
                  optionValue="id" optionLabel="login"
                  ></paggination-listbox>
                    <!-- <v-menu v-model="parentMenu" 
                    offset-y
                    :close-on-content-click="false" 
                        nudge-left="0" 
                        nudge-right="0"
                        
                        >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-column" v-on="on">
                          <span class="p-input-icon-right"  >
                            <i class="pi pi-angle-down" />
                              <InputText 
                              :value="getParentName"
                              type="text" readonly class="p-inputtext-sm fw" :placeholder="$t('parent_agent')">
                              </InputText>
                              </span>
                              </div>
                        </template>

                          <Listbox v-model="addPlayer.agent_id" :options="agents.items"
                          @change="parentMenu = false"
                          :filter="true"
                          class="custom-dropdown"
                          :emptyFilterMessage="$t('no_data')"
                          optionLabel="login" 
                          optionValue="id"
                          style="width:auto">
                         <!-- <template #value="slotProps">
                            
                      </template>
                    </Listbox>
                    
                      </v-menu> -->
                    <span v-if="errorParent || errors[0]" class="p-error mt-1 font-weight-bold">{{ $t('required_field') }}</span>
                  </div>
              </ValidationProvider>
              <ValidationProvider
              v-if="!isEditPlayer"
                  name="login"
                  rules="required|min3"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">{{ $t('login') }}<span class="c-red">*</span>:</h4>
                  <div class="d-flex flex-column">
                    <InputText 
                    autocomplete="off"
                    :maxlength="12"
                    v-model="addPlayer.username" 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                      :placeholder="$t('login')" 
                      
                      />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
                <ValidationProvider
                  name="password"
                  :rules="isEditPlayer ? 'min:8' : 'required|min8'"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">{{ $t('password') }}<span class="c-red" v-if="!isEditPlayer">*</span>:</h4>
                  <div class="d-flex flex-column">
                    <Password 
                    autocomplete="off"
                    v-model="addPlayer.password"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                    class="fw" 
                    :placeholder="$t('password')" 
                    toggleMask 
                    :feedback="false"
                    />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
              <ValidationProvider
                  name="name"
                  rules=""
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">{{ $t('name') }}:</h4>
                  <div class="d-flex flex-column">
                    <InputText 
                     
                    :maxlength="50"
                    v-model="addPlayer.name" 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                      :placeholder="$t('name')" 
                      
                      />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
              <ValidationProvider
                  name="email"
                  rules="email"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">{{ $t('email') }}:</h4>
                  <div class="d-flex flex-column">
                    <InputText 
                    v-model="addPlayer.email" 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                      :placeholder="$t('email')" 
                      
                      />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
              <ValidationProvider
                  name="tel"
                  rules=""
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">{{ $t('tel') }}:</h4>
                  <div class="d-flex flex-column">
                    <InputMask 
                    mask="(999) 999-9999" 
                    v-model="addPlayer.tel" 
                    class="p-inputtext-sm fw"
                     placeholder="(999) 999-9999" />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
              <template v-if="!isEditPlayer">
                <ValidationProvider
                  name="balance"
                  rules="required|min_value:0"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">{{ $t('balance') }}:</h4>
                  <div class="d-flex flex-column">
                    <InputNumber 
                    locale="en-US"
                    class="fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                     currencyDisplay="code"
                    mode="currency" 
                    :currency="getCurrencyValue"
                    :placeholder="$t('balance')" 
                    v-model="addPlayer.balance" 
                    showButtons  
                    :step="1"
                    :min="0"
                    />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
              <span>
                <h4 class="c-text white-space">
                  <p class="mb-0">{{ $t('currency') }}:</p>
                </h4>
                          <!-- <InputText 
                          :disabled="isAgent"
                          :value="admin && admin.currency ? admin.currency : ''"
                          type="text" readonly class="p-inputtext-sm fw" :placeholder="$t('currency')">
                          </InputText> -->
                          <v-menu v-model="currencyMenu" 
                offset-y
                :close-on-content-click="false" 
                    nudge-left="0" 
                    nudge-right="0"
                    
                    >
                    <template v-slot:activator="{ on }">
                      <div class="d-flex flex-column" v-on="on">
                      <span class="p-input-icon-right"  >
                        <i class="pi pi-angle-down" />
                        <!-- :disabled="isAgent || addPlayer.agent_id || addPlayer.agent_id != null" -->
                          <InputText 
                          disabled
                          :value="getCurrencyValue"
                          type="text" readonly class="p-inputtext-sm fw" :placeholder="$t('currency')">
                          </InputText>
                          </span>
                          </div>
                    </template>
                      <Listbox v-model="addPlayer.currency" :options="currencies"
                      :filter="true"
                      @change="currencyMenu = false"
                      class="custom-dropdown"
                      :emptyFilterMessage="$t('no_data')"
                      style="width:auto" />
        
                  </v-menu>
                          </span>
              </template>
              </v-card-text>
              <v-card-actions>
                  <Button 
                  @click="handleSubmit(validate)"
                  :disabled="formLoading"
                  :loading="formLoading"
                     :label="isEditPlayer ? $t('edit') : $t('create')"
                      icon="pi pi-save" class="p-button-sm p-button-success fw" />
              </v-card-actions>
            </ValidationObserver>
            </v-card>
            <info-about-new-player 
            :newPlayer='createdPlayer'
            @add-new="resetUserInfo"
            @close-popup="closeAll"
            v-else
            />
        </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import disableAutocomplete from '@/disable-autocomplete';
import InfoAboutNewPlayer from '@/views/AddNewPlayer/InfoAboutNewPlayer';
    export default {
        name: 'ModalActionsUserForAgent',
        components:{
          InfoAboutNewPlayer
        },
        props: {
            toAddDialog: {
                type: Boolean,
                default: false
            },
            isEditPlayer:{
              type: Boolean,
            },
            isCreatePlayer:{
              type: Boolean,
            },
            player:{
              type: Object,
            }
        },
        computed:{
          ...mapGetters({
            currencies: 'getFilteredCurrencies',
            agents: "agents/getAgents",
            admin: 'admin',
            tokenUser: 'tokenUser',
          }),
          getCurrencyValue(){
            // else if(this.isCreatePlayer && !this.addPlayer.agent_id){
            //   return this.addPlayer.currency;
            // }
            if (this.isAgent) {
              return this.admin.currency;
            }  else if (!this.isAgent && this.agents.items.length > 0) {
            const parentAgent = this.agents.items.find(agent => agent.id === this.addPlayer.agent_id);
            if (parentAgent) {
              return parentAgent.currency;
            } else {
              return 'USD';
            }
            } else{
              return 'USD';
            }
          },
          getAdminAvailableBalance(){
            if (this.isAgent) {
              this.balanceCheck = this.admin.credits_balance;
              return `${this.admin.credits_balance}`;
            } else if (!this.isAgent && this.agents.items.length > 0) {
            const parentAgent = this.agents.items.find(agent => agent.id === this.addPlayer.agent_id);
            if (parentAgent) {
              this.balanceCheck = parentAgent.credits_balance;
              return `${parentAgent.credits_balance}`; // parentAgent.credits_balance;
            } else{
              return '';
            }
            } else{
              return '';
            }
          },
          getParentName(){
            if (this.addPlayer.agent_id) {
              if (this.agents.items && this.agents.items.length > 0) {
      const parentAgent = this.agents.items.find(agent => agent.id === this.addPlayer.agent_id);
      if (parentAgent) {
        return parentAgent.login;
      } else{
        return '';
      }
    } else{
  
      return this.$t('no_parent');
    }
            } else if (this.tokenAgent === 'agent') {
              return this.admin.name;
            } else{
              return '';
            }
          },
          isAgent(){
            if (this.tokenUser === 'agent') {
              return 'agent';
            } else{
              return null;
            }
          },
        },
        data() {
            return {
              loadingList: false,
              parentMenu: false,
                formLoading: false,
                currencyMenu: false,
                parentMenu: false,
                dialog: false,
                showAdd: true,
                errorCount: false,
                loadingAgentsFlag: false,
              startAgentsLoadingFlag: false,
                balanceCheck: 0,
                errorParent: false,
                createdPlayer: {},
                addPlayer: {
                  agent_id: '',
                  username: "",
                  password: "",
                  name: '',
                  currency: 'USD',
                  email: "",
                  tel: "",
                  balance: 0
                },
                filters:{
                  page: 1,
                  size: 50,
                },
                holdCreatePlayer: {},
                holdEditPlayer: {},
                loadingList: false,
            }
        },
        mounted() {
          this.holdCreatePlayer = this.addPlayer;
          // this.$nextTick(() => {
          //   console.log('this.admin.id', this.admin.id);
            
          //   if (this.isAgent && this.admin.id) {
          //     this.addPlayer.agent_id = this.admin.id; 
          //   }
          // })
        },
        watch:{
          admin: {
            handler(newVal) {
              if (newVal && this.isAgent) {
                this.addPlayer.agent_id = this.admin.id;
              }
            },
            immediate: true,
          },
          isCreatePlayer: {
            handler(newVal) {
              if (newVal && this.holdCreatePlayer) {
                this.addPlayer = this.holdCreatePlayer;
              }
            },
            immediate: true
          },
          isEditPlayer: {
            handler(newVal) {
              if (newVal) {
                const player = this.player
                this.addPlayer = {
                  agent_id: this.isAgent ? this.admin.id : null,
                  username: player.username,
                  password: "",
                  name: player.name,
                  email: player.email,
                  tel: player.tel,
                  balance: player.balance
                };
                this.holdEditPlayer = {...this.addPlayer};
              }
            },
            immediate: true
          },
          dialog: {
            handler(newVal) {
              if (newVal === false) {
                this.$emit('dialog');
                
                if (this.toAddDialog) {
                  if (this.isCreatePlayer) {
                    this.holdCreatePlayer = this.addPlayer;
                    this.resetUserInfo();
                  }
                  this.$nextTick(() => {
                    
                    this.$refs.observer.reset();
                  })
                }
              } else{
                // this.resetUserInfo();
              }
            },
            immediate: true
          },
          toAddDialog: {
            handler(newVal) {
              this.dialog = newVal;
              this.$nextTick(() => {
                  disableAutocomplete.disable();
                })
            },
            immediate: true
          }
        },
        methods: {
          async startAgentsLoading(){
          if (this.agents.items.length) {
            return
          }
          this.startAgentsLoadingFlag = true;
          const filters = {
            size: 100,
            page: 1
          }
          try {
            await this.$store.dispatch("agents/awaitGetAgents", filters);
          } catch (error) {
            this.$toast.add({ severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 4000 })
          } finally{
            this.startAgentsLoadingFlag = false;
          }
        },
        async loadAgentsNextPage(){
          this.loadingAgentsFlag = true;
          const filters = {
            size: 100,
            page: this.agents.page + 1
          }
          try {
            await this.$store.dispatch("agents/awaitGetAgents", filters);
          } catch (error) {
            this.$toast.add({ severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 4000 })
          } finally{
            this.loadingAgentsFlag = false;
          }
        },
          toMaxCount(){
            if (this.isAgent) {
              this.addPlayer.balance = this.admin.credits_balance;
            } else{
              this.addPlayer.balance = Number(this.getAdminAvailableBalance);
            }
          },
          handleSubmit(validate) {
            validate().then(valid => {
        if (valid) {
          this.errorParent = false;
          if (this.isEditPlayer) {
            this.toAddPlayer();
            return;
          }
          if (!this.addPlayer.agent_id && !this.isAgent) {
            this.errorParent = true;
            return;
          }
          if (this.addPlayer.balance > this.balanceCheck) {
                this.errorCount = true;
            } else{
                this.errorCount = false
                this.toAddPlayer();
            }
        }
      });
    },
          closeAll(){
            this.$emit('dialog');
            this.dialog = false;
            this.showAdd = true;
          },
          getUpdatedFields() {
      const create = this.addPlayer;
      const edit = this.holdEditPlayer;
      let userData = {};

      if (create.password && create.password.trim() !== (edit.password ? edit.password.trim() : '')) {
        userData.password = create.password.trim();
      }
      if (create.name && create.name.trim() !== (edit.name ? edit.name.trim() : '')) {
        userData.name = create.name.trim();
      }
      if (create.email && create.email.trim() !== (edit.email ? edit.email.trim() : '')) {
        userData.email = create.email.trim();
      }
      if (create.tel && create.tel.trim() !== (edit.tel ? edit.tel.trim() : '')) {
        userData.tel = create.tel.trim();
      }

      return userData;
    },
            async toAddPlayer(){
          this.formLoading = true;
          try {
            const create = this.addPlayer;
            let userData = {}
            let method
            if(!this.isEditPlayer){
              if (!this.isAgent) {
                userData.agent_id = create.agent_id;
              } else{
                userData.agent_id = this.admin.id;
              }
              userData.username = create.username
              userData.balance = create.balance
              method = 'awaitCreateNewUserAgent';
              if (create.password) {
              userData.password = create.password
            }
            if (create.name) {
              userData.name = create.name
            }
            if (create.email) {
              userData.email = create.email
            } 
            if (create.tel) {
              userData.tel = create.tel
            } 
            } else{
              method = 'awaitEditAgentUser';
              userData = this.getUpdatedFields();
              // const updatedFields = this.getUpdatedFields();
            }
            // console.log(userData);
            this.createdPlayer = await this.$store.dispatch(`agents/${method}`, {id: this.player.id, type: this.isAgent, data: userData});
            
            this.$toast.add({severity: 'success', summary: this.isEditPlayer ? this.$t('player_updated') : this.$t('player_added'), life: 3000}) 
            if (!this.isAgent) {
      await this.$store.dispatch("agents/awaitGetAgents", this.filters);
    } else{
      await this.$store.dispatch('getAgentStatus');
    }
    if (!this.isEditPlayer) {
      this.$emit('added')
      this.showAdd = false;
      this.holdCreatePlayer = {
            agent_id: null,
            username: '',
            password: "",
            name: '',
            email: '',
            tel: '',
            balance: 0
          };
      this.addPlayer = {
            agent_id: null,
            username: '',
            password: "",
            name: '',
            email: '',
            tel: '',
            balance: 0
          };
    } else{
              this.$emit('added-edit')
              this.resetUserInfo();
            }
          } catch (error) {
            if (error.response && error.response.status && error.response.status === 409) {
              if (error.response.data.detail.loc === 'username') {
                this.$toast.add({severity: 'error', summary: this.$t('player_already_exists'), life: 3000})
                this.addPlayer.username = '';
              } else if (error.response.data.detail.loc === 'tel') {
                this.$toast.add({severity: 'error', summary: this.$t('tel_already_exists'), life: 3000})
                this.addPlayer.tel = '';
              } else if (error.response.data.detail.loc === 'email') {
                this.$toast.add({severity: 'error', summary: this.$t('email_already_exists'), life: 3000})
                this.addPlayer.email = '';
              } else if (error.response.data.detail.loc === 'password') {
                this.$toast.add({severity: 'error', summary: this.$t('use_another_password'), life: 3000})
                this.addPlayer.password = '';
              }
            } else{
            this.$toast.add({severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 4000}) 
            }
          } finally{

            this.formLoading = false; 
          }
            // this.showAdd = false;
        },
        resetUserInfo(){
          this.errorCount = false;
          this.showAdd = true;
          this.addPlayer.username = "";
          this.addPlayer.password = "";
          this.addPlayer.name = "";
          this.addPlayer.email = "";
          this.addPlayer.tel = "";
          this.addPlayer.balance = 0;
           // agent_id: this.admin.id,
        },
        },
    }
</script>

<style lang="scss" scoped>

.v-card__text{
  >span{
    display: flex;
    align-items: center;
    //justify-content: space-between;
    h4{
      flex: 1 1 auto;
    }
    >div{
      flex: 0 0 300px;
    }
    .p-component{
      max-width: 300px;
      //flex: 0 0 300px;
    }
  }
}
</style>